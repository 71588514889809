<!-- eslint-disable max-len -->
<template class="sweepform-container">
  <div class="modeldetails" style="border-radius: unset !important;overflow-x: hidden !important;">
  <div class="black-text font-family-2">
  <Loader v-if="showLoading" />
    <template v-if="!showLoading">
          <div class="sweep-card">
          <div class="px-0 titlebar">
            <v-row class="ml-0 title-header">
              <v-col v-if="getInvites" class="d-flex align-center py-0 pl-0" cols="10">
                <span class="modal-header-title"> Reschedule Sweep </span>
              </v-col>
              <v-col v-else class="d-flex align-center py-0 pl-0" cols="10">
                <span class="modal-header-title"> Sweep Details </span>
              </v-col>
              <v-col class="d-flex justify-end py-0 pr-4 close-btn">
                <v-icon
                  color="darken-1"
                  @click="cancel">mdi-close</v-icon>
              </v-col>
            </v-row>
          </div>
            <v-form>
              <div class="add-sweep-form">
                <!-- For Businesses input -->
                <span v-if="!clients">
                <v-row v-if="showBusiness" class="ma-0 business-row mb-5">
                  <v-col class="pb-0 business-col pa-0" cols=12>
                  <label class="business-label">Business*</label>
                  <div class="business-tab1">
                    <AutoCompleteField
                      placeholder="Select..."
                      append-icon="mdi-chevron-down"
                      :key="sweep.businessId"
                      :items="this.businesessname"
                      name="businessId"
                      item-text="name"
                      item-value="id"
                      class="type-selection notranslate businessId"
                      :value="sweep.businessId"
                      @change="setBusinessesName"
                      :status="errors('businessId').length ? 'error': 'normal'"
                      :error-messages="errors('businessId')"
                      @input="$v.sweep.businessId.$touch()"
                      @blur="$v.sweep.businessId.$touch()"
                    />
                  </div>
                  </v-col>
                </v-row>
                </span>
                <v-row class="ma-0 title-row pb-0">
                  <v-col cols=12 class="pa-0 title-col"
                    :class="{
                      'errorState v-input--has-state error--text': titleerr,
                    }"
                  >
                    <label class="title-label">Title*</label>
                    <BaseInput
                      :class="{
                        'errorState v-input--has-state error--text': titleerr,
                      }"
                      class="title-input"
                      solo
                      dense
                      flat
                      v-model.trim="sweep.title"
                      placeholder
                      name="title"
                      @input="checkForTitle"
                      @blur="checkForTitle"
                    />
                  </v-col>
                </v-row>
                <span
                  class="ml-5"
                  :class="{
                    'errorState v-input--has-state error--text': titleerr,
                  }"> {{ titleerror }} </span>
                <v-row class="row-2">
                  <v-col md=4 class="datepicker pt-0">
                    <label>Date*</label>
                    <v-row>
                    <v-col class="date-icon-div">
                      <span>
                        <img src="@/assets/svg/theme/calender.svg" />
                      </span>
                    </v-col>
                    <v-col class="datepicker-div">
                    <date-picker
                      solo
                      flat
                      class="date-picker"
                      content-class="date-picker"
                      no-title
                      :initialValue="sweep.date"
                      v-model="sweep.date"
                      :minValue="currentDate"
                      append-icon=""
                    />
                    </v-col>
                    </v-row>
                  </v-col>
                  <v-col class="time-col pt-0">
                    <div class="w-fit">
                      <label>Time*</label>
                      <TimePicker
                      :timeErr="fromTimeErr"
                      parentComponent="sweepForm"
                      outlined
                      dense
                      append-icon="mdi-chevron-down"
                      class="fromTime-input"
                      :initial="sweepTimeInitial"
                      :format="timeFormat"
                      @change="fromTimeset"
                      :time="sweep.fromTime"
                      @onTimeChange="(val) => setSweepTime(val, 'fromTime', 'fromTimeErr')"
                      @onErrorChage="(val) => onError(val)"
                      @blur="onBlur('fromTime', 'fromTimeErr')"
                      />
                    </div>
                  </v-col>
                  <v-col class="time-to">
                    <div class="time-to-text">-</div>
                  </v-col>
                  <v-col class="time-col align-center pt-0">
                    <div class="w-fit2">
                      <TimePicker
                        :timeErr="toTimeErr"
                        v-bind:value="sweep.toTime"
                        class="toTime-input"
                        parentComponent="sweepForm"
                        outlined
                        dense
                        :initial="sweepTimeInitial"
                        append-icon="mdi-chevron-down"
                        :format="timeFormat"
                        :time="sweep.toTime"
                        name="toTime"
                        :optionsAfter="sweep.fromTime"
                        @onTimeChange="(val) => setSweepTime(val, 'toTime', 'toTimeErr')"
                        @onErrorChage="(val) => onError(val)"
                        @blur="onBlur('toTime', 'toTimeErr')"
                      />
                    </div>
                  </v-col>
                  <v-col class="time-zone">
                    <v-select
                      v-model="sweep.timezone"
                      :items="getTimezones"
                      item-text="timezone"
                      item-value="id"
                      name="timezone"
                      class="type-selection notranslate timezone"
                    >
                    </v-select>
                  </v-col>
                </v-row>
                <div
                class="mobile-error pa-0 d-flex justify-end pb-1 mr-8"
                :key="timeErrors('fromTime').length > 0">
                  <span class="errorText v-messages__message error--text">
                    {{ timeErrors('fromTime').length > 0
                    || timeErrors('toTime').length > 0
                    ? timeErrors('fromTime')[0] : null }}</span
                  >
                </div>
                <v-row class="row-3 mt-2"
                  v-for="(input, key) in sweep.locations"
                  :key="input">
                  <v-col class="location-input">
                      <v-row class="location-row"
                        :class="{
                          'errorState v-input--has-state error--text': locationErr &&
                          !input.location,
                          }"
                      >
                      <v-col class="location-div">
                      <v-row class="location-label">
                      <label
                    > Location #{{ key + 1 }}*</label>
                    </v-row>
                      <div
                        class="v-input__control v-input input-field
                          v-input--is-label-active v-input--is-dirty
                          theme--light v-text-field v-text-field--single-line
                          v-text-field--solo v-text-field--solo-flat v-text-field--is-booted
                          v-text-field--enclosed"
                        :class="{
                            'errorState v-input--has-state error--text': locationErr &&
                            !input.location,
                        }">
                      <div class="v-input__slot">
                        <v-col class="date-icon-div"
                        >
                        <span>
                          <img src="@/assets/svg/theme/location.svg"/>
                        </span>
                        </v-col>
                        <div class="v-text-field__slot">
                            <vue-google-autocomplete
                              :types="['establishment']"
                              :component-restrictions="null"
                              :id="input.key"
                              :key="input.key"
                              placeholder=""
                              v-model="input.location"
                              item-value="location"
                              @input="checkForLocation"
                              @blur="checkForLocation"
                              append-outer-icon="mdi-map-marker"
                              name="locations"
                              class="location-field"
                              v-on:placechanged="(data) => getAddressData(data, input.key)"
                              v-on:inputChange="(data) => setLocation(data, input.key)"
                            >
                            </vue-google-autocomplete>
                        </div>
                      </div>
                    </div>
                  <div class="v-text-field__details ml-3"
                  :class="{
                    'errorState v-input--has-state error--text': locationErr,
                  }"
                  v-if="locationErr && !input.location"
                  >
                  <div class="v-messages theme--light error--text" role="alert">
                  <div class="v-messages__wrapper">
                      <div class="v-messages__message">Please provide valid location</div>
                  </div>
              </div>
            </div>
                  </v-col>
                </v-row>
                  </v-col>
                  <v-col cols="3" class="py-0 pr-0 ps-0 mb-0 ml-3">
                  <v-row class="pt-1">
                  <label class="ml-8"
                    >Location Setting</label>
                  </v-row>
                  <v-row class="flex-column p-0">
                    <v-row class="flex-row">
                    <v-row class="locations-div ml-11 mb-0">
                      <v-select
                      :items="settings"
                      append-icon="mdi-chevron-down"
                      name="settings"
                      v-model="input.settings"
                      class="type-selection mb-n3 notranslate mb-0 settings"
                      >
                    </v-select>
                    </v-row>
                    </v-row>
                  </v-row>
                </v-col>
                <v-col cols="4" class="py-0 pr-0 ps-0 mb-0 ml-3"
                >
                  <v-row class="pt-1">
                  <label class="ml-8"
                    >Zipcode</label>
                  </v-row>
                  <v-row class="flex-column pt-0">
                    <v-row class="flex-row pt-0">
                    <v-row
                      :class="{
                        'errorState v-input--has-state error--text': locationErr &&
                        !input.zipcode,
                      }"
                      class="locations-div ml-11 mb-0 pt-0">
                      <BaseInput
                      placeholder
                      name="zipcode"
                      min="0"
                      type="tel"
                      @input="checkForLocation"

                      :maxLength="7"
                      @blur="checkForLocation"
                      v-model="input.zipcode"
                      class="type-selection notranslate mb-0 settings zipcode"
                    />
                    </v-row>
                    <v-col class="justify-end cursor-pointer mb-0 pb-0">
                    <v-icon
                        color="darken-1"
                        class="remove-btn"
                        @click="removeLocation(key)"
                        v-show="key || ( !key && sweep.locations.length > 1)"
                        >
                          mdi-close
                    </v-icon>
                    </v-col>
                    </v-row>
                  </v-row>
                  <v-row>
            </v-row>
                                <v-row class="ml-5"
                                v-bind:class = "(sweep.locations.length > 1)?'pt-3':'pt-8'"
                                >
              <span
                :class="{
                  'errorState v-input--has-state error--text': locationErr,
                }"
                v-if="(locationErr && !input.zipcode)
                || (/^\d+$/.test(input.zipcode)
              && input.zipcode.length >= 6 && input.zipcode.length <= 10)">Please provide valid zipcode</span>
            </v-row>
                </v-col>
          <div class="addlocation-col">
          <div
              class="addLocation"
              v-show="key == sweep.locations.length-1"
              @click="addLocation(key)">
              + Add Location
          </div>
        </div>
      </v-row>
      <v-row v-if="clients" style="height: 160px;">
        <v-col class="ml-5 mr-6 pb-0">
          <label>Notes</label>
          <BaseTextAreaField
              :rows="5"
              name="notes"
              class="sweepNotes"
              dense
              solo
              v-model.trim="sweep.notes"
            />
        </v-col>
      </v-row>
            <div class="heading">Invites & Attendees</div>
                <v-row v-if="this.affiliatedBusinesses.length" class="ma-0 invite-row">
                  <v-col class="affiliated-div">
                  <label class="affiliated-label pt-0">Affiliated Business</label>
                  <div class="business-tab2 pt-0">
                    <AutoCompleteField
                      placeholder="Select..."
                      append-icon="mdi-chevron-down"
                      :key="sweep.businessIds"
                      :items="this.affiliatedBusinesses"
                      multiple
                      name="businessIds"
                      item-text="name"
                      item-value="id"
                      class="type-selection notranslate"
                      :value="sweep.businessIds"
                      @change="setBusinesses"
                      @input="sweep.businessIds.$touch()"
                      @blur="sweep.businessIds.$touch()"
                    />
                  </div>
                  </v-col>
                </v-row>
              <v-row v-for="(input,k) in sweep.creatives" :key="k"
                  class="creative-row mb-0 pb-0 pl-2 mt-3"
                  >
              <v-row class="row-4 mb-0 pb-0">
                    <v-col class="creative-col py-0 pr-0 mb-0 pb-0">
                      <label class="creative-label ml-3">Creative*</label>
                        <v-col class="creative-div py-0 pr-0 mb-0 pb-0"
                              :class="{
                                'errorState v-input--has-state error--text': creativesErr &&
                                !input.creative
                              }">
                        <v-select
                          :class="{
                            'errorState v-input--has-state error--text': creativesErr &&
                            !input.creative
                          }"
                          :items="creativeData(input.creative)"
                          placeholder="Select..."
                          append-icon="mdi-chevron-down"
                          :menu-props="{ 'content-class': 'notranslate' }"
                          v-model="input.creative"
                          name="creatives"
                          item-value="creatives"
                          class="type-selection notranslate"
                        >
                      </v-select>
                      <v-col class="pa-0">
                        <span
                          :class="{
                            'errorState v-input--has-state error--text': creativesErr,
                          }"
                          v-if="creativesErr && !input.creative">Please provide creative</span>
                      </v-col>
                      </v-col>
                  </v-col>
                  <v-col
                  :style="[clients ? {'max-width': '150px'} : {'max-width': '120px'}]"
                  class="qty-col py-0 mb-0 pb-0">
                  <label class="qty">QTY</label>
                  <v-row>
                  <v-col class="qty-div py-0 pr-0 mb-0 pb-0">
                    <v-select
                    :items="qtyOptions"
                    append-icon="mdi-chevron-down"
                    v-model="input.quantity"
                    name="creatives"
                    item-text="qty"
                    class="type-selection notranslate qty"
                    >
                    </v-select>
                  </v-col>
                  <div style="width: 40px;" v-if="clients" class="cursor-pointer pl-4 mt-3 mb-0 pb-0">
                    <v-icon color="darken-1" @click="remove(k)"
                      v-show="k || ( !k && sweep.creatives.length > 1)">
                        mdi-close
                    </v-icon>
                  </div>
                </v-row>
                  </v-col>
                  <v-col v-if="!clients" class="payout-col py-0 pr-0 mb-0 pb-0">
                    <label class="payout">Payout (total)</label>
                    <v-row class="payout-row mb-0 pb-0">
              <v-col class="payout-div mb-0 pb-0 ml-3">
                <v-text-field
                  :class="{
                    'errorState v-input--has-state error--text': creativesErr && !input.payout
                  }"
                  outlined
                  dense
                  v-model="input.payout"
                  name="payout"
                  min="0"
                  oninput="validity.valid || (value = value.slice(0,value.length-1))"
                  class="title-input payout-field"
                  @input="checkForCreative"
                  @blur="checkForCreative"
                  type="number"
                  step="0.01"
                >
                <template v-slot:prepend-inner class="dollar-div">
                  <div class="dollar text-left">
                    <Dollar color="#939393"/>
                  </div>
                </template>
              </v-text-field>
              </v-col>
              <v-row class="cursor-pointer mb-0 pb-0">
                <v-icon color="darken-1" @click="remove(k)"
                v-show="k || ( !k && sweep.creatives.length > 1)">
                mdi-close
                </v-icon>
              </v-row>
            </v-row>
            <v-col class="pa-0">
              <span
                :class="{
                  'errorState v-input--has-state error--text': creativesErr,
                }"
                v-if="creativesErr && !input.payout">Please provide payout</span>
            </v-col>
            </v-col>
          </v-row>
          <v-row class="addequipment" >
              <div
                @click="addCreative(k)"
                v-if="canAddCreative"
                v-show="k == sweep.creatives.length-1"
                class="addcreative"
              >
              + Add Creative
              </div>
          </v-row>
        </v-row>
        <div v-if="getInvites && photographer.length > 0 || videographer.length > 0 || creativetalent.length > 0 || fieldproducer.length > 0" class="invite-creatives">
          <div
          class="heading">Creative Invite Queue</div>
          <v-col class="mt-0 pt-0 ml-2">
            <v-col
            class="pa-0 mt-2"
            v-for="(creative, index) in photographer" :key="creative"
            style="width: 400px;min-width: fit-content;">
            <div v-if="index === 0" :key="index" class="invite-headings">PHOTOGRAPHER</div>
              <v-col
              class="d-flex no-wrap creatives-data mb-2 mt-1 pa-2">
              <Grid width="18" height="15" color="#929292" class="mt-3"></Grid>
                <AvatarRound
                class="creative-icon ml-2 mr-2"
                :size="35"
                :avatar="creative.creatives.profilePic"
                :name="memberInitials(creative.creatives)"
                textStyle="font-size: 15px;"
                />
                <span style="margin-top: 6px;">{{creative.creatives.firstName}}</span>
                <v-col class="d-flex justify-end py-0 pr-0">
                  <v-icon
                  :key="index"
                  @click="removeCreative(creative.creatives.id, index, creative)">mdi-close</v-icon>
                </v-col>
              </v-col>
            </v-col>
            <v-col
            class="pa-0 mt-2"
            v-for="(creative, index) in videographer" :key="creative"
            style="width: 400px;min-width: fit-content;">
          <div v-if="index === 0" :key="index" class="invite-headings">VIDEOGRAPHER</div>
              <v-col
              class="d-flex no-wrap creatives-data mb-2 mt-1 pa-2">
              <Grid width="18" height="15" color="#929292" class="mt-3"></Grid>
                <AvatarRound
                class="creative-icon ml-2 mr-2"
                :size="35"
                :avatar="creative.creatives.profilePic"
                :name="memberInitials(creative.creatives)"
                textStyle="font-size: 15px;"
                />
                <span style="margin-top: 6px;">{{creative.creatives.firstName}}</span>
                <v-col class="d-flex justify-end py-0 pr-0">
                  <v-icon
                  :key="index"
                  @click="removeCreative(creative.creatives.id, index, creative)">mdi-close</v-icon>
                </v-col>
              </v-col>
            </v-col>
            <v-col
            class="pa-0 mt-2"
            v-for="(creative, index) in creativetalent" :key="creative"
            style="width: 400px;min-width: fit-content;">
            <div v-if="index === 0" :key="index" class="invite-headings">TALENT</div>
              <v-col
              class="d-flex no-wrap creatives-data mb-2 mt-1 pa-2">
              <Grid width="18" height="15" color="#929292" class="mt-2"></Grid>
                <AvatarRound
                class="creative-icon ml-2 mr-2"
                :size="35"
                :avatar="creative.creatives.profilePic"
                :name="memberInitials(creative.creatives)"
                textStyle="font-size: 15px;"
                />
                <span style="margin-top: 6px;">{{creative.creatives.firstName}}</span>
                <v-col class="d-flex justify-end py-0 pr-0">
                  <v-icon
                  :key="index"
                  @click="removeCreative(creative.creatives.id, index, creative)">mdi-close</v-icon>
                </v-col>
              </v-col>
            </v-col>
            <v-col
            class="pa-0 mt-2"
            v-for="(creative, index) in fieldproducer" :key="creative"
            style="width: 400px;min-width: fit-content;">
            <div v-if="index === 0" :key="index" class="invite-headings">FILEDPRODUCER</div>
              <v-col
              class="d-flex no-wrap creatives-data mb-2 mt-1 pa-2">
              <Grid width="18" height="15" color="#929292" class="mt-3"></Grid>
                <AvatarRound
                class="creative-icon ml-2 mr-2"
                :size="35"
                :avatar="creative.creatives.profilePic"
                :name="memberInitials(creative.creatives)"
                textStyle="font-size: 15px;"
                />
                <span style="margin-top: 6px;">{{creative.creatives.firstName}}</span>
                <v-col class="d-flex justify-end py-0 pr-0">
                  <v-icon
                  :key="index"
                  @click="removeCreative(creative.creatives.id, index, creative)">mdi-close</v-icon>
                </v-col>
              </v-col>
            </v-col>
          </v-col>
          </div>
              <v-row class="ma-0 buttons">
                  <v-col class="d-flex justify-end px-0 mt-8">
                    <v-btn
                    text
                    class="mr-4 cancel"
                    color="primaryGray1"
                    @click="cancel">Cancel</v-btn>
                    <v-btn :loading="loading" class="save-btn btn-purple"
                      id="savebutton"
                      type="submit"
                      @click="submitForm"
                    >
                      Save
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </div>
    </template>
  </div>
</div>
</template>

<script>
import {
  required, requiredIf,
} from 'vuelidate/lib/validators';
import _ from 'lodash';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import BaseTextAreaField from '@/components/common/BaseTextAreaField';
import BaseInput from '@/components/common/BaseInput';
import DatePicker from '@/components/common/DatePicker';
import TimePicker from '@/components/common/TimePicker';
import AutoCompleteField from '@/components/common/AutoCompleteField';
import Dollar from '@/components/common/svg/Dollar';
import Loader from '@/components/common/Loader';
import AvatarRound from '@/components/common/Avatar';
import Grid from '@/components/common/svg/Grid';


export default {
  name: 'SweepForm',
  components: {
    TimePicker,
    BaseInput,
    DatePicker,
    VueGoogleAutocomplete,
    AutoCompleteField,
    Dollar,
    Loader,
    AvatarRound,
    Grid,
    BaseTextAreaField,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    orderId: {
      type: Number,
    },
    deliverableId: {
      type: Number,
    },
    showBusiness: {
      type: Boolean,
      default: false,
    },
    sweepData: {
      default: false,
    },
    getInvites: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('sweepBlocks', ['getTimezones']),
    ...mapGetters('user', ['userDetails']),
    role() {
      return _.get(this.userDetails, 'role.name');
    },
    userId() {
      return _.get(this.userDetails, 'currentBusinessId');
    },
    clients() {
      return ['agency_owner', 'member', 'agency_member', 'client'].includes(this.role);
    },
    memberInitials() {
      return (member) => {
        const firstName = _.get(member || member, 'firstName', '').trim();
        const lastName = _.get(member || member, 'lastName', '').trim();
        let name = `${firstName} ${lastName}`;
        name = name.trim() || _.get(member, 'displayName', '').trim();
        if (name) {
          if (name.indexOf(' ') > 0) {
            const first = name.split(' ');
            name = first[0].charAt(0) + first[1].charAt(0);
          } else {
            name = name.charAt(0) + name.charAt(1);
          }
        }
        return name;
      };
    },
    creativeData() {
      return (val) => {
        const { creatives } = this.sweep;
        const selectcreative = _.map(creatives, 'creative');
        const arr = [];
        _.forEach(this.creativeOptions, (item) => {
          if (!selectcreative.includes(item.text)) {
            arr.push(item.text);
          }
          if (val === item.text) {
            arr.push(item.text);
          }
        });
        return arr;
      };
    },
    canAddCreative() {
      const { creativeOptions } = this;
      return this.sweep.creatives.length < creativeOptions.length;
    },
    timeErrors() {
      return (field) => {
        const errors = [];
        switch (field) {
          case 'fromTime':
          case 'toTime':
            if (this.sweep.fromTime) {
              if (moment(this.sweep.fromTime, ['HH:mm']).isAfter(moment(this.sweep.toTime, ['HH:mm']))) {
                errors.push('Please select a time that is not in the past');
              }
              if (moment(this.sweep.fromTime, ['HH:mm']).isSame(moment(this.sweep.toTime, ['HH:mm']))) {
                errors.push('Start time and End time cannot be same.');
              }
            } else if (this.sweep.toTime) {
              if (moment(this.sweep.toTime, ['HH:mm']).isBefore(moment(this.sweep.fromTime, ['HH:mm']))) {
                errors.push('Please select a time that is not in the past');
              }
            }
            break;
          default:
            break;
        }
        return errors;
      };
    },
    errors() {
      return (field) => {
        const errors = [];
        if (!this.$v.sweep[field].$dirty) return errors;
        switch (field) {
          case 'businessId':
            if (!this.$v.sweep.businessId.required) {
              errors.push('Please provide business');
            }
            break;
          case 'title':
            if (!this.$v.sweep.title.required) { errors.push('Please provide valid title'); }
            break;
          case 'fromTime':
            if (!this.$v.sweep.fromTime.required) { errors.push('Please provide from time'); }
            if (!this.$v.sweep.fromTime.validTime) { errors.push('Please select a time that is not in the past'); }
            break;
          case 'toTime':
            if (!this.$v.sweep.toTime.required) { errors.push('Please provide to time'); }
            if (!this.$v.sweep.toTime.validTime) { errors.push('Please select a time that is not in the past'); }
            break;
          default:
            break;
        }
        return errors;
      };
    },
    currentDate() {
      if (this.clients) {
        return moment().add(2, 'days').format('YYYY-MM-DD');
      }
      return moment().format('YYYY-MM-DD');
    },
    projectId() {
      return this.$route.params.projectId;
    },
  },
  data() {
    return {
      showLoading: true,
      autocompleteOptions: {
        types: ['establishment'],
      },
      titleerr: false,
      titleerror: '',
      fromTimeErr: '',
      toTimeErr: '',
      sweepTimeErr: false,
      sweepTimeInitial: true,
      timeFormat: 'HH:mm:ss',
      loading: false,
      fromTime: false,
      toTime: false,
      removeCreatives: [],
      affiliatedBusinesses: [],
      businesessname: [],
      sweepTeamData: [],
      photographer: [],
      videographer: [],
      creativetalent: [],
      fieldproducer: [],
      sweep: {
        businessId: '',
        title: '',
        date: moment().format('YYYY-MM-DD'),
        locations: [{
          key: `location-${Date.now()}`,
          settings: '1',
          location: '',
          zipcode: '',
        }],
        creatives: [{
          creative: 'Photographer',
          quantity: '1',
          payout: '',
        }],
        description: '',
        timezone: 1,
        fromTime: '08:00',
        toTime: '10:00',
        isPreSweepToOwner: true,
        businessIds: [],
      },
      creativesErr: false,
      locationErr: false,
      settings: [
        { text: 'Indoor', value: '1' },
        { text: 'Outdoor', value: '2' },
        { text: 'Flex', value: '3' },
      ],
      creativeOptions: [
        { text: 'Photographer', value: 'maxPhotographer' },
        { text: 'Videographer', value: 'maxVideographer' },
        { text: 'Talent', value: 'maxTalent' },
        { text: 'Field Producer', value: 'maxFieldProducer' },
      ],
      qtyOptions: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
    };
  },
  methods: {
    ...mapActions('sweep', ['updateSweep', 'reScheduleSweep']),
    ...mapActions('sweepBlocks', ['addSweepCreate', 'setTimeZone', 'autoTimeZone']),
    ...mapActions('project', ['getMyBusinesses', 'getProject']),
    ...mapActions('organizations', ['getAgencyBusinesses']),
    removeCreative(val, index, creative) {
      // eslint-disable-next-line max-len
      if (creative.skill === '1') {
        this.photographer.splice(index, 1);
      } else if (creative.skill === '2') {
        this.videographer.splice(index, 1);
      } else if (creative.skill === '3') {
        this.creativetalent.splice(index, 1);
      } else {
        this.fieldproducer.splice(index, 1);
      }
      this.removeCreatives.push({
        creativeId: val,
        sweepId: this.sweep.id,
      });
    },
    setSweepTime(val, field, fieldErr) {
      this.sweepTimeInitial = false;
      this.sweep[field] = val;
      this.onBlur(field, fieldErr);
    },
    onError(val) {
      this.sweepTimeErr = val;
    },
    onBlur(field, fieldErr) {
      if (_.isEmpty(this.sweep[field]) || this.sweep[field] === 'Invalid date') {
        this[fieldErr] = 'Invalid Time';
      } else {
        this[fieldErr] = '';
      }
    },
    fromTimeset() {
      this.sweep.toTime = (moment((this.sweep.fromTime), ['HH:mm']).add(120, 'minutes').format('HH:mm'));
      return this.sweep.toTime;
    },
    async setBusinessesName(newval) {
      this.sweep.businessId = newval;
      if (newval) {
        const { businesses } = await this.getAgencyBusinesses({
          businessId: newval,
          status: 1,
        });
        this.affiliatedBusinesses = _.map(businesses, (business) => ({
          name: business.name,
          id: business.id,
        }));
      } else {
        this.affiliatedBusinesses = [];
      }
    },
    async setBusinesses(newval) {
      this.sweep.businessIds = newval;
    },
    addLocation() {
      this.sweep.locations.push({
        key: `location-${Date.now()}`,
        location: '',
        settings: '1',
        latitude: '',
        longitude: '',
        zipcode: '',
      });
    },
    removeLocation(index, key) {
      this.sweep.locations.splice(index, 1);
      this.locations = this.locations.filter((loc) => loc.key !== key);
    },
    async setLocation(location, key) {
      const loc = this.sweep.locations.find((l) => l.key === key);
      if (loc) {
        loc.location = location.newVal.trim();
      }
      this.sweep.locations = [...this.sweep.locations];
    },
    async getAddressData(location, key) {
      // const placeset = new window.google.maps.places.Placeservice(document.createElement('div'));
      // console.log('afda', placeset);
      const loc = this.sweep.locations.find((l) => l.key === key);
      if (loc && location.postal_code) {
        loc.zipcode = location.postal_code;
        const result = await this.autoTimeZone({
          zipcode: loc.zipcode,
        });
        if (result.success) {
          this.sweep.timezone = result.data.id;
        }
      }
      this.sweep.locations = [...this.sweep.locations];
    },
    async checkForTitle() {
      if (!this.sweep.title) {
        this.titleerr = true;
        this.titleerror = 'Please provide title';
      } else if (this.sweep.title.length > 160) {
        this.titleerr = true;
        this.titleerror = 'Title must be less than 160 characters';
      } else {
        this.titleerr = false;
        this.titleerror = '';
      }
    },
    async checkForLocation() {
      const { locations } = this.sweep;
      let err = false;
      if (locations.length === 0) {
        err = true;
      } else {
        err = false;
        _.map(locations, async (item) => {
          if (item.zipcode) {
            // eslint-disable-next-line
            item.zipcode = item.zipcode.toUpperCase().replace(/[^0-9A-Z]/g, '');
          }
          if (item.zipcode.length === 5) {
            const result = await this.autoTimeZone({
              zipcode: item.zipcode,
            });
            if (result.success) {
              this.sweep.timezone = result.data.id;
            }
          } else if ((new RegExp(/^[A-Za-z]\d[[A-Za-z][ -]?\d[A-Za-z]\d$/)).test(item.zipcode.value)) {
            console.info('Canadian postal code detected.');
          } else if (!item.location || !item.zipcode || !item.zipcode.length) {
            err = true;
          }
        });
      }
      this.locationErr = err;
    },
    async checkForCreative() {
      const { creatives } = this.sweep;
      let err = false;
      if (creatives.length === 0) {
        err = true;
      } else {
        err = false;
        _.map(creatives, (item) => {
          if (this.clients) {
            if (!item.creative) {
              err = true;
            }
          }
          if (!this.clients) {
            if (!item.payout || !item.creative) {
              err = true;
            }
          }
        });
      }
      this.creativesErr = err;
    },
    addCreative() {
      this.sweep.creatives.push({
        creative: '',
        quantity: '1',
        payout: '',
      });
    },
    remove(index) {
      this.sweep.creatives.splice(index, 1);
    },
    cancel() {
      this.setInitialState();
      if (this.orderId) {
        if (this.clients) {
          this.$router.push({
            path: '/sweeps',
          });
        } else {
          this.$router.push({
            path: `/project_overview/${this.$route.params.projectId}?businessId=${this.$route.query.businessId}&activeTab=sweeps`,
          });
        }
      }
      this.$emit('close-modal');
    },
    setInitialState() {
      this.sweep = {
        businessId: '',
        title: '',
        date: moment().format('YYYY-MM-DD'),
        locations: [{
          key: `location-${Date.now()}`,
          settings: '',
          location: '',
          latitude: '',
          longitude: '',
          zipcode: '',
        }],
        creatives: [{
          creative: '',
          quantity: '1',
          payout: '',
        }],
        notes: '',
        timezone: 1,
        fromTime: '',
        toTime: '',
        isPreSweepToOwner: true,
        businessIds: [],
      };
    },
    async submitForm(e) {
      this.loading = true;
      this.sweepTimeInitial = false;
      const timeErr = this.timeErrors('fromTime').length > 0;
      this.onBlur('fromTime', 'fromTimeErr');
      this.onBlur('toTime', 'toTimeErr');
      this.checkForTitle();
      this.checkForLocation();
      this.checkForCreative();
      this.$v.$touch();
      e.preventDefault();
      if (!this.$v.$invalid
      && !timeErr && !this.sweepTimeErr
      && !this.creativesErr && !this.locationErr && !this.titleerr) {
        let businessId;
        if (this.projectId) {
          const { project } = await this.getProject(this.projectId);
          // eslint-disable-next-line
          businessId = project.businessId;
        }
        const payload = Object.assign(this.sweep,
          {
            // projectId: this.projectId,
            businessId: businessId || this.sweep.businessId,
            businessIds: JSON.stringify(this.sweep.businessIds),
            orderId: this.orderId ? this.orderId : this.sweep.orderId,
            deliverableId: this.deliverableId ? this.deliverableId : this.sweep.deliverableId,
          });
        let result;
        if (this.sweep.id && this.getInvites === false) {
          await this.updateSweep(payload);
          this.setInitialState();
          this.$emit('close-modal');
        }
        if (!this.sweepData && this.getInvites === false) {
          result = await this.addSweepCreate(payload);
          if (result.success) {
            this.setInitialState();
            if (this.orderId) {
              if (this.clients) {
                this.$router.push({
                  path: '/sweeps',
                });
              } else {
                this.$router.push({
                  path: `/project_overview/${this.$route.params.projectId}?businessId=${this.$route.query.businessId}&activeTab=sweeps`,
                });
              }
            }
            this.$emit('close-modal', true);
            this.$emit('reload-sweeps', true);
          }
        }
        if (this.getInvites === true) {
          const payloadd = Object.assign(this.sweep,
            {
              sweepId: JSON.parse(this.$route.params.sweepId),
              businessId: businessId || this.sweep.businessId,
              businessIds: JSON.parse(this.sweep.businessIds),
              removeCreatives: this.removeCreatives,
            });
          result = await this.reScheduleSweep(payloadd);
          if (result.success) {
            this.setInitialState();
            setTimeout(() => {
              this.$emit('get-sweep');
              this.$emit('close-modal', true);
            }, 500);
          }
        }
      }
      this.loading = false;
    },
  },
  validations: {
    sweep: {
      businessId: {
        // eslint-disable-next-line func-names
        required: requiredIf(function () {
          return this.showBusiness;
        }),
      },
      title: { required },
    },
  },
  async mounted() {
    this.setTimeZone();
    let businessId;
    if (this.projectId) {
      const { project } = await this.getProject(this.projectId);
      // eslint-disable-next-line
      businessId = project.businessId;
    }
    if (this.clients) {
      // eslint-disable-next-line
      businessId = this.userId;
      this.sweep.businessId = this.userId;
      this.sweep = {
        ...this.sweep,
        date: moment().add(2, 'days').format('YYYY-MM-DD'),
      };
    }
    if (businessId) {
      const { businesses } = await this.getAgencyBusinesses({
        businessId,
        status: 1,
      });
      this.affiliatedBusinesses = _.map(businesses, (business) => ({
        name: business.name,
        id: business.id,
      }));
    }
    if (!this.clients) {
      const businessName = await this.getMyBusinesses();
      this.businesessname = _.map(businessName.businesses, (business) => ({
        id: business.id,
        name: business.name,
      }));
    }
    if (this.sweepData) {
      this.sweep = { ...this.sweep, ...this.sweepData };
      this.sweep.locations = this.sweepData.sweepLocations.map((x) => ({
        ...x,
        key: x.id,
      }));
      this.sweep.date = this.sweepData.date;
    }
    if (this.getInvites) {
      this.sweep.businessIds = [];
      this.sweep.creatives = [{
        creative: 'Photographer',
        quantity: '1',
        payout: '',
      }];
      // eslint-disable-next-line
      this.sweepTeamData = this.sweep.sweepTeam.filter((x) => (x.accepted || x.accepted === null) && (!x.deletedAt && !x.declinedAt));
      this.photographer = this.sweepTeamData.filter((x) => x.skill === '1');
      this.videographer = this.sweepTeamData.filter((x) => x.skill === '2');
      this.creativetalent = this.sweepTeamData.filter((x) => x.skill === '3');
      this.fieldproducer = this.sweepTeamData.filter((x) => x.skill === '4');
      const { businesses } = await this.getAgencyBusinesses({
        businessId: this.$route.params.businessId,
        status: 1,
      });
      this.affiliatedBusinesses = _.map(businesses, (business) => ({
        name: business.name,
        id: business.id,
      }));
    }
    this.showLoading = false;
  },
};
</script>

<style scoped lang="scss">
::v-deep .sweepNotes .v-textarea .v-input__slot {
  box-shadow: none !important;
}
.dollar {
  background-color: #F5F5F5;
  border: 1px solid #E0E0E0;
  width: 35px;
  height: 44px;
  padding: 8px;
  border-radius: 3px 0px 0px 3px;
}
.payout-div {
  ::v-deep {
    .v-input__slot {
      .v-input__prepend-inner {
        margin-top: 0px !important;
      }
    }
  }
}
.zipcode {
  ::v-deep {
    .v-input {
      padding-top: 0px;
      height: 10px;
      input {
        max-height: 30px !important;
      }
    }
  }
}
.w-fit {
  width: 120px;
}
.w-fit2 {
  width:120px;
  padding-top: 20px;
  padding-right: 0px !important;
}
.business-row {
  padding: 20px !important;
  height: 100px;
  padding-bottom: 0px;
}
.title-row {
  max-height: 90px;
  ::v-deep .errorState .v-input .v-input__control .v-input__slot {
    border: solid 1px #ff0808 !important;
  }
  .title-input {
    ::v-deep.theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
      border: 1px solid #E0E0E0;
      height: 40px !important;
    }
  }
}
.time-col {
  max-width: 135px;
  padding-left: 0px;
  padding-right: 0px;
  padding: 10px;
  .v-input-slot {
    min-height: 44px !important;
  }
}
.time-col,.location-input,.payout-div {
  ::v-deep.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) >
  .v-input__control > .v-input__slot fieldset {
    color: #E0E0E0 !important;
  }
}
.titlebar{
  .title-header {
    padding: 16px 21px 21px;
  }
  top:0;
  background:white;
  border-bottom: 1px solid #D1D1D1;
  position: sticky !important;
  position:-webkit-sticky;
  z-index: 5;
}
.title-col {
  height: 79px;
  padding: 20px !important;
  padding-bottom: 0px !important;
  padding-top: 14px !important;
}
.row-2 {
  max-height: 70px;
  padding: 10px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.row-3 {
  width:650px;
  margin-left: 0px !important;
  margin-right: 0px !important;
  .remove-btn {
    width: 20px;
    padding-bottom: 0px !important;
  }
}
.row-4 {
  min-width: 550px;
  height: 80px;
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.buttons {
  padding-right: 20px;
  min-width: 550px;
  margin-top: 10px !important;
}
.sweep-card {
  background-color: white;
  width: 725px;
  padding: 0px !important;
  .text-area {
    border: 1px solid #E0E0E0;
    border-radius: 3px;
    padding-top: 10px;
    width: 100%;
    ::v-deep.v-text-field__details {
      display: none !important;
    }
  }
  ::v-deep .v-input__slot {
    min-height: 38px !important;
  }
}
.save-btn ::v-deep .v-btn__content {
  font-size: 16px !important;
}
.location-row {
  flex-wrap: nowrap !important;
}
.sweep-card {
  ::v-deep {
    .theme--light.v-text-field > .v-input__control > .v-input__slot:before {
      border-color:white !important;
    }
    .v-text-field > .v-input__control > .v-input__slot:before {
      border-style: none !important;
    }
    .v-text-field > .v-input__control >
    .v-input__slot:before, .v-text-field > .v-input__control > .v-input__slot:after {
      content: none !important;
    }
    .creative-div {
      .error--text {
        .v-input__slot {
          border: 1px solid red !important;
        }
      }
    }
    .type-selection {
    .v-input__slot {
      border: solid 1px #e6e8ed;
      padding:5px;
      border-radius: 3px;
    }
  }
  .error--text {
  line-height: 12px;
  font-size: 12px !important;
  }
  .v-text-field--outlined.v-input--dense.v-text-field--outlined
  > .v-input__control > .v-input__slot {
    min-height: 44px !important;
  }
  }
}
.location-input {
  align-items: center;
  padding-right: 0px;
  padding-left:20px;
  height: 80px;
  max-width: 245px;
  padding-top: 5px;
  margin-bottom: 5px;
  ::v-deep .errorState .v-input .v-input__control .v-input__slot {
    border: solid 1px transparent !important;
  }
  ::v-deep.error--text {
    .v-input__slot {
      border: 1px solid red !important;
    }
  }
  .v-input__slot {
    margin-left: 12px !important;
    min-height: 44px !important;
    border: 1px solid #E0E0E0!important;
    ::v-deep.v-text-field__slot {
      padding-left: 5px;
      width: 355px !important;
    }
  }
  .v-input__control {
    max-height: 45px !important;
    max-width: 100%;
  }
  .close {
    padding-left: 5px;
    font-size: 1.4375rem;
  }
}
label {
  font-weight: 600 !important;
  color: #262626;
}
.title-label {
  width:675px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #262626;
}
.location-label{
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 10px;
  line-height: 21px;
  color: #262626;
  width: 100px;
  margin-left: 14px;
  margin-bottom: 5px;
  padding-bottom: 0px
}
.location-div {
  padding-left:0px;
  padding-top: 0px;
  padding-bottom: 0px;
}
.location-err {
  padding: 0px !important;
  padding-right: 20px;
}
.locations-div {
  max-width: 170px;
  .type-selection {
    padding-top: 0px;
    max-width: 150px;
  }
}
.locationset-col {
  padding: 0px;
  ::v-deep.v-text-field {
    margin-top: 0px;
    padding-top: 0px;
  }
}
.addlocation-col {
  min-width:550px;
  padding-left:10px;
  .addLocation {
    cursor: pointer;
    font-family: $fontFamily1;
    line-height: 18px;
    font-weight: 400;
    font-size: 13px;
    padding-left:12px;
    color: $secondary1;
    width: fit-content;
  }
}
.addcreative {
    cursor: pointer;
    font-family: $fontFamily1;
    line-height: 18px;
    font-weight: 400;
    font-size: 13px;
    padding-left:5px;
    color: $secondary1;
    width: fit-content;
}
.addlocation-btn {
  padding-top: 0px;
}
.datepicker,.payout-col {
  .date-icon-div {
    background-color: #F5F5F5;
    border: 1px solid #E0E0E0;
    max-width:40px;
    height: 44px;
    margin-left:10px;
    border-radius: 3px 0px 0px 3px;
  }
}
.location-input {
  .date-icon-div {
    background-color: #F5F5F5;
    border-right: 1px solid #E0E0E0;
    max-width:40px;
    height: 42px;
    border-radius: 3px 0px 0px 3px;
  }
}
.datepicker {
  max-width: 220px;
  margin-right: 10px;
  ::v-deep.v-text-field .v-input__slot {
      border: 1px solid #E0E0E0;
      margin-left: -1px !important;
      border-top-left-radius: 0px !important;
      border-bottom-left-radius: 0px !important;
  }
  ::v-deep.v-input__slot {
    min-height: 44px !important;
    max-width: 170px;
  }
  .datepicker-div {
    padding: 0px;
  }
  ::v-deep .v-picker--date {
    border-radius: 4px!important;
  }
}
  .tiptap-vuetify-editor {
    ::v-deep .v-toolbar {
      border-top-left-radius: inherit;
      border-top-right-radius: inherit;
    }
  }
  .time-to-text {
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color:#262626;
}
.time-to {
  padding-top: 28px !important;
  max-width: 0px !important;
}
  .sweep-detail, .font-label {
    font-family: $fontFamily1;
  }
  .time-zone {
    padding: 0px;
    max-width: 80px;
    ::v-deep.theme--light.v-select .v-select__selection--comma {
      color: black !important;
    }
    ::v-deep .type-selection {
      font-size: 16px;
      font-weight: 600;
      .v-input__icon {
        display: none;
      }
      .v-select__selections {
        border-bottom: 2px dashed #8066FF !important;
        max-width: 45px;
      }
      .v-input__slot {
        border: none !important;
        border-radius: 3px;
        margin-top: 6px;
      }
      .v-text-field > .v-input__control > .v-input__slot:before {
        border-style: none !important;
      }
    }
}
.heading {
  font-weight: 600 !important;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 30px;
  font-size: 20px !important;
  line-height: 30px !important;
  color: #929292 !important;
  border-bottom: 1px solid #DEDEDE;
}
.invite-row{
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-top: 0px;
  width: 700px;
  .affiliated-div {
    max-height: 80px;
    ::v-deep.v-text-field {
      padding-top: 0px !important;
    }
  }
}
.business-tab1 {
  height: 65px;
  border-bottom: 5px solid #ECECEC;
  ::v-deep.v-text-field {
    padding-top: 0px !important;
  }
  .type-selection {
    ::v-deep.v-input__slot {
      padding-bottom: 0px !important;
      margin-bottom: 2px !important;
    }
  }
}
.affiliated-div,.business-col {
  .type-selection {
    padding-top: 0px !important;
    ::v-deep.error--text {
      .mdi-chevron-down::before {
        font-size: 25px !important;
      }
    }
    ::v-deep.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
      border-color: #E0E0E0 !important;
      border-style: none;
    }
    ::v-deep.error--text {
      .v-input__slot {
      border: 1px solid red !important;
      }
    }
  }
}
.creative-row {
  height: 80px;
  .creative-col {
    max-width: 250px;
    ::v-deep.type-selection {
      ::v-deep.v-select__selections {
        line-height: 20px !important;
      }
    }
  }
  // .qty-col {
  //   max-width: 150px;
  // }
}
.creative-div,.qty-div {
  ::v-deep.type-selection {
    margin-top: 0px;
  }
  ::v-deep.v-text-field {
    height: 45px !important;
    padding-top: 0px !important;
  }
}
.payout-col {
  .cursor-pointer {
    padding: 0px !important;
    padding-bottom: 25px !important;
    padding-left: 20px !important;
  }
}
.payout-row {
  height: 41px;
}
.payout-div {
  padding: 0px !important;
  max-width:70px;
  max-width:97px;
  ::v-deep.v-text-field--outlined.v-input--dense.v-text-field--outlined >
  .v-input__control > .v-input__slot {
    padding-left: 0px !important;
  }
}
.addequipment {
  min-width: 300px;
  color: $secondary1;
  flex-wrap: nowrap;
  padding: 0px;
  margin: 0px;
  margin-left: 20px !important;
  margin-right: 0px !important;
  ::v-deep.v-icon.v-icon.v-icon--link {
    height: 20px !important;
  }
}
.invite-creatives {
  .invite-headings {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #929292;
  }
  .creatives-data {
    border: 1px solid #d2d2d2;
    border-radius: 10px;
  }
}
.zipcode {
  padding-top: 0px;
}
</style>
